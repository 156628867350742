/* global $, ga */
$(document).ready(function(){
	/* infield active */
	$("form.search .wrap").addClass("infield-active");
	$("#course-search").addClass("infield-active");
	$(".contactForm").addClass("infield-active");
	$(".enrol-form").addClass("infield-active");
	$(".infield").inFieldLabels();

	$("#course-view").click(function(){
		$(".course-description").toggle();

		if($(this).text() == "Collapse Courses" ){
			$(this).text("Expand Courses");
		}
		else {
			$(this).text("Collapse Courses");
		}
	});

	$("#map-click").click(function(){
		$("#map-click").hide();
	});

	if ($("body").hasClass("t-splash")) {
		var $wrapper = $(".scroll-wrapper");
		var $scroller = $(".category-scroller");
		var $slider = $scroller.find(".category-slider");
		var $prev = $wrapper.find(".prev");
		var $next = $wrapper.find(".next");
		$prev.click(function(){
			//animate slide left
			$slider.animate({"margin-left":"-100%"}, function(){
				//move items to end
				for (var i =0;i<3;i++)
					$slider.append($slider.find(".category:first"));
				$slider.css("margin-left","0");
			});

		});
		$next.click(function(){
			//animate slide right
			$slider.css("margin-left","-100%");
			//move items to start
			for (var i =0;i<3;i++)
				$slider.prepend($slider.find(".category:last"));

			$slider.animate({"margin-left":"0%"});

		});
		$("body").one("touchstart", function(){
			$prev.hide();
			$next.hide();
			$slider.css("overflow-x","scroll");
		});
	}
	if (($("body").hasClass("t-course")) && ($(".course-group").length > 0)) {
		$(".course-group li.drop").click(function(){
			$(this).toggleClass("active").find("ul.course-list").toggleClass("active");
		});
	}
	setupSlideMenu();

	$(".duration-more-info-link, .duration-more-info-details").click(function(){
		$(this).parent().find(".duration-more-info-details").toggleClass("active");
	});

	$("#course-finder select").change(function(){
		var action_url = "/content/course/";
		if ($(this).val() != "") {
			action_url += $(this).val()+"/";
		}
		$(this).closest("form").attr("action",action_url);
	});

	//Allow tables to scroll for content that otherwise won't fit mobile
	$("#content table").wrap("<div class=\"table-responsive-wrap\"></div>");

	$(".enroll-now").click(function(){
		$(this).toggle();
		$(".enrol-form").toggle();
		if (typeof ga == "function") {
			ga("send", "event", "Enrol", "open", $(".enroll-now").data("course-name"));
			ga("send", "pageview", "virtual-enrolment-open");
		}
	});
	bannerRotate($(".banners-home"));
	$("#schedule_id").change(function(){
		//Store the human readable display of the schedule info in a form hidden field
		$("#start_date").val($("#schedule_id option:selected").text());
		$("#schedule_detail").val($("#schedule_id option:selected").data("schedule"));
	});
});

export function setupSlideMenu(){

	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

export function bannerRotate($container){
	function bannerFade(){
		var $current = $container.find(".banner.active");
		var $next = $current.next();
		if ($next.length == 0)
			$next = $container.find(".banner:first");
		$current.removeClass("active");
		$next.addClass("active");
	}
	setInterval(bannerFade,7000);
}